import { Injectable } from '@angular/core';
import { ISingleFranchise, TFeatureType } from 'apps/early-stage-office/src/app/core/models/franchise.interface';

@Injectable({
    providedIn: 'root',
})
export class FranchiseAccessService {
    public static readonly features: TFeatureType[][] = [
        ['test'], // nieprzypisane
        ['test-2'], // feature1
        ['invitations'], // feature2
        ['send-declarations'], // feature3
        ['send-agreements'], // feature4
    ];

    constructor() {}

    public static checkAccess(featureType: TFeatureType, data: ISingleFranchise): boolean {
        const featureIndex = this.getFeatureIndex(featureType);

        if (featureIndex === 0) {
            return false;
        }

        if (featureIndex === null) {
            return true;
        }

        return data['feature' + featureIndex];
    }

    private static getFeatureIndex(feature: TFeatureType): 0 | 1 | 2 | 3 | 4 | null {
        const index = this.features.findIndex(f => f.indexOf(feature) >= 0);

        if (index !== null && index >= 0 && index < 5) {
            return index as 0 | 1 | 2 | 3 | 4;
        }

        return null;
    }
}
