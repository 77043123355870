import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class TitleService {
    private _data: { [key: string]: string } = {};
    private _prefix: string;
    private _sufix: string;

    constructor(
        private _title: Title,
        private route: ActivatedRoute
    ) {}

    public getTitle(): string {
        return this._title.getTitle();
    }

    public resolveTitle(): void {
        const rawTitle = (this._prefix || '') + (this.getRouteTitle() || '') + (this._sufix || '');
        let title = rawTitle;

        if (rawTitle) {
            const reg = /:\S+/gm;
            const results = rawTitle.match(reg);

            if (results) {
                for (const r of results) {
                    const key = r.replace(':', '');
                    title = title.replace(r, this._data[key] || '');
                }
            }

            // remove trailing whitespaces and dashes
            while (title.slice(-1) === ' ' || title.slice(-1) === '•') {
                title = title.slice(0, -1);
            }

            // remove doubled whitespaces and dashes
            while (title.includes('  ') || title.includes('• •')) {
                title = title.replace('  ', ' ');
                title = title.replace('• •', '•');
            }
        }

        this._title.setTitle(title);
    }

    public addData(key: string, value: string): void {
        this._data[key] = value;

        this.resolveTitle();
    }

    public setPrefix(prefix: string): void {
        this._prefix = prefix;
    }

    public setSufix(sufix: string): void {
        this._sufix = sufix;
    }

    private getRouteTitle(): string {
        let child = this.route;
        let lastChild = this.route;

        while (child) {
            lastChild = child;
            child = child.firstChild;
        }

        return this.getParentRouteTitle(lastChild.snapshot);
    }

    private getParentRouteTitle(route: ActivatedRouteSnapshot): string {
        if (!route) {
            return null;
        }

        if (route.data.title) {
            return route.data.title;
        }

        const parentTitle = this.getParentRouteTitle(route.parent);

        if (parentTitle) {
            return parentTitle;
        }

        return null;
    }
}
