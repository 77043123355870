import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SetDisabledSchoolYears, SetMinimalSchoolYear, SetSelectedSchoolYear } from './school-year-selector.actions';
import { DictionaryState, IDictionaryStateModel } from '../dictionary/dictionary.state';

export interface ISchoolYearSelectorStateModel {
    selectedYear: number;
    minimalSchoolYear: number;
    disabledYears: number[];
    disabledYearTooltip: string;
}

@State<ISchoolYearSelectorStateModel>({
    name: 'schoolYearSelector',
    defaults: {
        selectedYear: null,
        minimalSchoolYear: null,
        disabledYears: [],
        disabledYearTooltip: null,
    },
})
@Injectable()
export class SchoolYearSelectorState {
    @Selector()
    public static getState(state: ISchoolYearSelectorStateModel) {
        return state;
    }

    @Selector()
    public static selectedSchoolYearId(state: ISchoolYearSelectorStateModel) {
        return state.selectedYear;
    }

    @Selector()
    public static disabledYears(state: ISchoolYearSelectorStateModel) {
        return {
            years: state.disabledYears,
            tooltip: state.disabledYearTooltip,
        };
    }

    @Selector([SchoolYearSelectorState, DictionaryState])
    public static selectedSchoolYear(state: ISchoolYearSelectorStateModel, dictionary: IDictionaryStateModel) {
        return dictionary.schoolYears.find(y => y.id === state.selectedYear);
    }

    @Selector([SchoolYearSelectorState, DictionaryState])
    public static nextSchoolYear(state: ISchoolYearSelectorStateModel, dictionary: IDictionaryStateModel) {
        return dictionary.schoolYears.find(y => y.id === state.selectedYear + 1);
    }

    @Selector([SchoolYearSelectorState, DictionaryState])
    public static schoolYears(state: ISchoolYearSelectorStateModel, dictionary: IDictionaryStateModel) {
        const min = state.minimalSchoolYear || 0;
        const max = dictionary.schoolYears.find(y => y.isCurrentDraft).id;

        return dictionary.schoolYears.filter(y => y.id >= min && y.id <= max);
    }

    @Selector([SchoolYearSelectorState, DictionaryState])
    public static isSelectedSchoolYearArchived(state: ISchoolYearSelectorStateModel, dictionary: IDictionaryStateModel) {
        return dictionary.schoolYears.find(y => y.isCurrent).id > state.selectedYear;
    }

    @Action(SetSelectedSchoolYear)
    public setSelectedSchoolYear(ctx: StateContext<ISchoolYearSelectorStateModel>, { payload }: SetSelectedSchoolYear) {
        return ctx.patchState({
            selectedYear: payload.id,
        });
    }

    @Action(SetMinimalSchoolYear)
    public setMinimalSchoolYear(ctx: StateContext<ISchoolYearSelectorStateModel>, { payload }: SetSelectedSchoolYear) {
        return ctx.patchState({
            minimalSchoolYear: payload.id,
        });
    }

    @Action(SetDisabledSchoolYears)
    public setDisabledSchoolYears(ctx: StateContext<ISchoolYearSelectorStateModel>, { payload }: SetDisabledSchoolYears) {
        return ctx.patchState({
            disabledYears: payload.ids || [],
            disabledYearTooltip: payload.disableTooltip,
        });
    }
}
