import { IAgencySchool } from './models/agency.interface';
import { IStudentSchoolData, IStudentSchoolDataDTO } from './models/student.interface';

export function isAgencySchool(
    school:
        | {
              id: number;
          }
        | IAgencySchool
        | IStudentSchoolData
        | IStudentSchoolDataDTO
): school is IAgencySchool {
    return (school as IAgencySchool)?.id !== undefined;
}

export function isCustomSchool(
    school:
        | {
              id: number;
          }
        | IAgencySchool
        | IStudentSchoolData
        | IStudentSchoolDataDTO
): school is IStudentSchoolData | IStudentSchoolDataDTO {
    return (
        (
            school as {
                id: number;
            }
        )?.id === undefined
    );
}
